.p-header {
    width: 100%;
    margin-bottom: 1.5rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: space-between;
    background-color: #eaeeef;
  }
  .p-header__h1 {
    font-size: 0.875rem;
    line-height: 2.25;
    font-weight: normal;
    letter-spacing: 0.05em;
    font-style: italic;
  }
  .p-header__h1::before,
  .p-header__h1::after {
    content: ' - ';
  }
  .p-header__txts {
    min-width: 270px;
  }
  .p-header__copy {
    font-size: 1.65rem;
    font-weight: bold;
    padding-right: 0.5rem;
  }
  .p-header__img {
    width: calc(100% - 290px);
    max-width: 130px;
  }

/* ログイン画面 */
.l-rootContainer:has(.p-header--guest){
    background-color: #EAEEEF;
    justify-content: space-between;
    height: 100dvh;
    height: 100vh;
  }
  .p-header--guest{
    flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 2rem;
      text-align: center;
      margin-top: 2.5rem;
  }
  .p-header--guest .p-header__img{
    width: 100%;
  }
  .p-header--guest .p-header__copy{
    padding-right: 0;
    margin-bottom: 1rem;
    letter-spacing: 0.2em;
  }  


  .menu-item {
    text-align: left;
  }
  
  .menu-button {
    font-size: 1.25rem;
    display: flex;
    gap: 1rem;
    align-items: center;
    cursor: pointer;
    color: #20314C;
  }

  .menu-button span {
    font-size: 1rem;
  }


/* ログイン後のヘッダー */
.p-header--logged-in{
    position: relative;
    width: 100%;
    background-color: #20314C;
  }
  .p-header-title{
    color: #fff;
    font-weight: bold;
    text-align: center;
    padding: 0.75rem;
  }

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

:root {
  background-color: #f4f6f7;
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Arial, "Zen Kaku Gothic New", sans-serif;
}
body {
  margin: 0;
}

/* 
* animation
*/
@keyframes my-fadein {
  from {
    opacity: 0.5;
    transform: translateY(-4px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* 
* reset
*/
a {
  background-color: transparent;
  text-decoration: none;
  color: inherit;
}
p {
  margin: 0;
}
input:focus {
  border: 2px solid rgba(33, 49, 76, 50%);
  border: none;
  box-shadow: none;
  outline: none;
}
button:hover {
  cursor: pointer;
}

/* 
* utility
*/
.u-center{
  text-align: center;
}
.u-txtUpper {
  text-transform: uppercase;
}
.u-wid86{
  width: 86%;
}
.u-txt__right{
  text-align: right;
}
.u-small{
  font-size: 0.75rem;
}
.u-txt__gray{
  color: #6d6d6d;
}
.u-db{
  display: block;
}
.u-underline{
  text-decoration: underline;
  text-underline-offset: 3px;
}
.u-attention{
  color: #D0111B;
}
.u-fontc-gray{
  color: #ccc
}

/* 
* component
*/
.c-heading {
  font-size: 125%;
  font-weight: bold;
  margin-bottom: 1rem;
  margin-top: 5rem;
  letter-spacing: 0.05em;
}
.c-tasklist {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0;
}
.c-tasklist__item:not(.-checked) {
  background-color: #fff;
  filter: drop-shadow(0px 0px 20px rgba(35, 40, 71, 0.05));
  box-shadow: 0px 0px 10px rgba(35, 40, 71, 0.05);
  border: 1px solid #EAEEEF;
  padding: 1.5rem 1.5rem 1.5rem calc(3rem + 20px);
}
.c-tasklist__item.-checked {
  display: flex;
  gap: 0.5rem 1.5rem;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.5rem 0.5rem 0.5rem calc(3rem + 20px);
  border-radius: 0;
  padding-bottom: 1.5rem;
}
.c-tasklist__item.-checked:not(:last-child){
  border-bottom: 2px dashed #eee;
}
.c-tasklist__item {
  list-style: none;
  position: relative;
  border-radius: 15px;
  animation-name: my-fadein;
  animation-duration: 0.5s;
}
.c-tasklist__item.is-overdue::before{
  position: absolute;
  right: -6px;
  top: 0;
  transform: translateY(-30%);
  content: '';
  background-image: url(../public/icon-overdue.png);
  background-size: contain;
  width: 40px;
  height: 40px;
  display: inline-block;
}
.c-tasklist__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
.c-tasklist__item:not(.-checked) .c-tasklist__head {
  margin-bottom: 0.5rem;
}
.c-tasklist__doneBtn {
  position: absolute;
  left: 1.25rem;
  top: 50%;
  top: calc(50% - 0.5rem);
  transform: translateY(-50%);
  background-color: #fff;
}
.c-tasklist__body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}
.c-tasklist__btnwrap {
  display: flex;
  gap: 0.5rem;
}
.c-tasklist__time {
  color: #ccc;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  gap: 0.5em;
}
.c-tasklist__tag{
  position: absolute;
  left: 0;
  top: 1%;
  height: 98%;
  width: 0;
  border-radius: 1.25rem 0 0 1.25rem;
  border: 4px solid transparent;
}
.dueDate-edit{
  display: flex;
  align-items: center;
}

.c-inputBtn {
  background-color: #eaeeef;
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.2em 1em;
  font-family: inherit;
  cursor: pointer;
  transition: border-color 0.25s;
  font-size: 0.875rem;
}
.c-inputBtn.-done {
  background-color: #21314c;
  color: #fff;
}
.c-doneBtn:hover,
.c-inputBtn:hover {
  border-color: #21314c;
}
.c-doneBtn:focus,
.c-doneBtn:focus-visible,
.c-inputBtn:focus,
.c-inputBtn:focus-visible {
  outline: 4px auto #21314c;
}
.c-icon-trash{
  position: relative;
  width: 20px;
  margin-left: 4px;
}
.c-icon-trash::before{
  position: absolute;
  top: 4px;
  right: 0;
  content: '';
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(../public/icon-trash.svg);
  width: 20px;
  height: 20px;
}
.c-addBtn {
  background-color: #21314c;
  cursor: pointer;
  color: #fff;
  border-radius: 1rem;
  text-align: center;
  padding: 1rem;
  letter-spacing: 0.2em;
}

.c-restoreBtn,
.c-doneBtn {
  border-radius: 50%;
  border: 2px solid #efeeec;
  width: 32px;
  height: 32px;
  transition: border-color 0.25s;
}
.c-restoreBtn.-checked,
.c-doneBtn.-checked {
  background-color: #f9bb69;
  border: 2px solid #fde9cf;
}
.c-restoreBtn.-checked::after,
.c-doneBtn.-checked::after {
  content: '';
  position: absolute;
  top: 7px;
  left: 4px;
  mask-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2216%22%20height%3D%2213%22%20viewBox%3D%220%200%2016%2013%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M14%200.0195312L5.90602%208.05463L2%204.01953L0%206.01953L3.44714%209.71347L5.90602%2012.1724L8.36456%209.71347L16%202.07844L14%200.0195312Z%22%20fill%3D%22white%22%2F%3E%3C%2Fsvg%3E');
  background-color: #fff;
  width: 20px;
  height: 16px;
  mask-size: contain;
  display: inline-block;
  mask-repeat: no-repeat;
}
.c-doneBtn:hover {
  border-color: #21314c;
}

.c-errorMsg {
  color: #e63631;
}
.c-tag{
  border-radius: 25px;
  padding: 0.35rem 0.85rem;
  color: #fff;
  line-height: 1;
  font-weight: bold;
  font-size: 12px;
  width: fit-content;
}
.p-inputArea .c-tag:not(.is-selected){
  background-color: #eaeeef !important;
  color: #C2C8CE;
  font-size: 13px;
}
.p-tag-manager__header .c-tag{
  font-size: 13px;
}

@media (prefers-color-scheme: light) {
}

/* login */
.c-loginBtn{
  color: #fff;
  background-color: #20314C;
  text-align: center;
  padding: 1rem;
  font-weight: bold;
  border-radius: 50px;
  font-size: 1.125rem;
  letter-spacing: 0.1em;
  max-width: 320px;
  filter: drop-shadow(0px 0px 20px rgba(35, 40, 71, 0.05));
    box-shadow: 0px 0px 20px rgba(35, 40, 71, 0.05);
    width: 100%;
    margin: auto;
    display: block;
}

/* logout */
.c-logoutLink{
  text-decoration: underline;
  display: inline-block;
  width: fit-content;
  font-size: 0.875rem;
  text-underline-offset: 2px;
  margin-bottom: 2rem;
}

/* 
* layout
*/
.l-rootContainer {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  background-color: #fff;
  max-width: 420px;
  width: 100%;
  margin: auto;
  /* padding: 2.5rem 0; */
  overflow-y: auto;
  overflow-x: hidden;
}
.l-rootContainer > *:not(footer, header),
.p-footer__inner {
  width: 86%;
}

/* 
* project
*/

.p-doneArea,
.p-incompleteArea {
  margin-bottom: 2rem;
}
.p-inputArea {
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 15px;
  border: 2px solid #eaeeef;
  scroll-margin-top: 2rem;
}
.p-inputArea__inner{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#p-inputarea__input,
.p-inputarea__input {
  border-radius: 20px;
  padding: 1rem 1.5rem 1rem 1.5rem;
  font-size: 1rem;
  border: none;
  width: 100%;
  box-sizing: border-box;
  background-color: #eaeeef;
  ime-mode: active;
  letter-spacing: 0.1em;
  border: 2px solid #eaeeef;
  line-height: 1;

}
#p-inputarea__date:focus,
#p-inputarea__input:focus,
.p-inputarea__input:focus,
#p-inputarea__input:active,
.p-inputarea__input:active{
  border: 2px solid rgba(32, 49, 76, 0.8);
}
#p-inputarea__date::placeholder,
#p-inputarea__input::placeholder,
.p-inputarea__input::placeholder {
  color: #ccc;
}
#p-inputarea__date{
  background-color: #eaeeef;
  border-radius: 20px;
  padding: 0.35rem 0.75rem 0.35rem 1.25em;
  box-sizing: border-box;
  color: #000;
  text-align: center;
  width: 100%;
}
.p-inputarea__tag{
  position: relative;
  font-size: 13px;
  color: #D2D6DB;
  padding-left: 1rem;
  border-radius: 25px;
  border: 1px dashed #D2D6DB;
  width: fit-content;
  padding: 0 1rem 0 1.5rem;
  text-align: center;
  letter-spacing: -0.05em;
  line-height: 2;
  font-weight: bold;
}
.p-inputarea__tag::before{
  top: 50%;
  transform: translateY(-50%);
  left: calc(0.75rem + 4px);
  height: 9px;
  width: 1px;
}
.p-inputarea__tag::after{
  top: 50%;
  transform: translateY(-50%);
  left: 0.75rem;
  height: 1px;
  width: 9px;
}
.p-inputarea__tag::before,
.p-inputarea__tag::after{
  content: '';
  position: absolute;
  background-color: #D2D6DB;
}
.c-tasklist__time .react-datepicker-wrapper{
  max-width: 120px !important;
}

.p-footer {
  /* background-color: #21314c; */
  width: 100%;
  color: #21314c;
  letter-spacing: 0.1em;
  margin-bottom: 5rem;
}
.p-footer__inner {
  margin: auto;
}
.p-footer__menu {
  /* width: fit-content; */
  margin: 2rem auto;
}
.p-footer__menu li{
  position: relative;
  padding-left: 1rem;
  margin-bottom: 0.75rem;
}
.p-footer__menu li::before {
  position: absolute;
  top: calc(50% - 0.25rem);
  left: 0;
  content: "";
  width: 0.4rem;
  height: 0.4rem;
  background-color: #fff;
  border-radius: 50%;
}
.p-footer__copy {
  font-size: 0.75rem;
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 4rem;
}

.p-customSpinner{
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  z-index: 9999;
}
.l-rootContainer:has(.p-customSpinner){
  position: relative;
  height: 100dvh;
  height: 100vh;
}
.l-rootContainer:has(.p-customSpinner)::after{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #F4F6F7;
  height: 100dvh;
  height: 100vh;
  z-index: 0;
}
.p-datepicker{
  cursor: pointer;
}
.react-datepicker__close-icon::after{
  background-color: #20314C !important;
  transform: scale(1.15);
}
.react-datepicker__close-icon{
  padding-right: 1rem !important;
}
.p-desc{
  font-size: 0.875rem;
}
.p-desc.-small{
  font-size: 0.75rem;
  letter-spacing: 0.05em;
}
.p-nothing__ttl{
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.75rem;
}
.p-nothing__desc{
  margin-bottom: 1rem;
  font-size: 0.875rem;
}
.p-nothing__img{
  max-width: 300px;
  margin-bottom: 1rem;
}
.p-nothing__btn{
  width: 70%;
  letter-spacing: normal;
  border-radius: 50px;
  box-shadow: 4px 4px 10px rgba(35, 40, 71, 0.15);
}


/* カテゴリ */
.p-CategoryFilterList__wrap{
  margin-bottom: 1.5rem;
}
.p-catlist{
  display: flex;
  gap: 0.6rem;
  margin-bottom: 0.6rem;
  overflow-x: auto;
  white-space: nowrap;
}
.c-catBtn{
  padding: 0.3rem 1rem;
  background-color: #EAEEEF;
  /* color: #20314C; */
  color: #8F98A5;
  font-weight: bold;
  font-size: 0.875rem;
  border-radius: 10px;
  min-width: 56px;
}
.c-catBtn.-tag,
.c-catBtn.-sort,
.c-catBtn.-trash{
  position: relative;
  padding-left: 1.5rem;
  color: #8F98A5;
}
.c-catBtn.-trash::before{
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 9px;
  content: '';
  mask-size: contain;
  background-repeat: no-repeat;
  background-color: #8F98A5;
  mask-image: url(../public/icon-trash.svg);
  width: 14px;
  height: 14px;
}
.c-catBtn.-sort::before{
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 9px;
  content: '';
  mask-size: contain;
  background-repeat: no-repeat;
  background-color: #8F98A5;
  mask-image: url(../public/icon-sort.svg);
  width: 14px;
  height: 14px;
}
.c-catBtn.is-active{
  background-color: #20314C;
  color: #fff;
}
.c-catBtn:not(.is-active){
  background-color: #EAEEEF !important;
  color: #8F98A5 !important;
}
.c-catBtn.-sort.is-active::before,
.c-catBtn.-trash.is-active::before{
  background-color: #fff;
}
.c-catBtn.-tag svg{
  position: absolute;
  top: 50%;
  left: 7px;
  transform: translateY(-50%);
  font-size: 16px;
}
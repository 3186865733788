@keyframes slide-up {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }

  @keyframes slide-down {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(100%);
    }
  }

.bottom-menu {
    position: fixed;
    bottom: 0rem;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 420px;
  }
  
  .menu-list {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin: 0.25rem auto 0;
    background-color: #fff;
    display: flex;
    justify-content: space-around;
    padding: 1rem 0;
    z-index: 1000;
    background-color: #F4F6F7;
  }

  .menu-button{
    font-size: 1.75rem;
    color: #8F98A5;
  }
  .menu-button.-add{
    color: #20314C;
  }

/* タグマネージャー */
.p-tag-manager__header {
    border-radius: 1.25rem 1.25rem 0 0 ;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background: white;
    padding: 1rem 2.5rem 2rem;
    z-index: 1;
    border: 1px solid #eaeeef;
    box-shadow: 0 -20px 20px rgba(0, 0, 0, 0.05);
}
.p-tag-manager__header.is-open {
    animation: slide-up 0.3s ease-out;
}
.p-tag-manager__header.is-closing {
    animation: slide-down 0.3s ease-out;
} 
.p-tag-manager__title {
   text-align: center;
   margin-bottom: .5rem;
   display: flex;
   gap: 0.25rem;
   align-items: center;
   font-weight: bold;
   color: #20314C;
   margin-bottom: 2rem;
}   
.p-tag-manager__list{
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex-wrap: wrap;
}
.p-tag-manager__item {
    display: flex;
    justify-content: space-between;
    gap: 0.25rem;
    align-items: center;
}
.p-tag-manager__form {
    margin-top: 1rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;
}
.p-tag-manager__input{
    width: 100%;
}

.p-tag-manager__color-picker button {
    width: 20px;
    height: 20px;
    margin: 0 5px;
    border-radius: 50%;
}

.p-tag-manager__close-button{
    position: absolute;
    right: 1rem;
    top: 1rem;
    font-size: 1.5rem;
}